import { v4 as uuid } from "uuid";

import { ToastEventDetail } from "./models";

export enum ToastEvents {
  Show = "show",
}

export function triggerToast(opts: Omit<ToastEventDetail, "id">) {
  const event = new CustomEvent(ToastEvents.Show, {
    detail: {
      id: uuid(),
      dismissType: "auto",
      ...opts,
    },
  });

  dispatchEvent(event);
}
