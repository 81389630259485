import { bind, classNames, Component, h, signal, tag } from "omi";

import { NWToast, ToastEventDetail } from "@/components/base/nw-toast";
import { animateCss } from "@/globals/styles";
import { tailwind } from "@/tailwind";

import { ToastEvents } from "./utils";

@tag("nw-toast-manager")
export class NWToastManager extends Component {
  static css = [tailwind, animateCss];
  private toastQueue: Map<string, ToastEventDetail> = new Map();
  private currentToastId = signal<string | undefined>(undefined);
  private isVisible = signal(false);

  install() {
    addEventListener(ToastEvents.Show, this.handleShowToast);
  }

  @bind
  private handleShowToast(event: Event) {
    const eventDetail = (event as CustomEvent<ToastEventDetail>).detail;
    this.toastQueue.set(eventDetail.id, eventDetail);

    if (this.currentToastId.value == null) {
      this.displayNextToast();
    }
  }

  private displayNextToast() {
    const nextToast: [string, ToastEventDetail] | undefined = this.toastQueue.entries().next().value;

    if (!nextToast) return;

    const [id, eventDetails] = nextToast;
    this.isVisible.value = true;
    this.currentToastId.value = id;

    if (eventDetails.dismissType === "auto") {
      setTimeout(() => {
        this.handleOnClose();
      }, 5000);
    }
  }

  @bind
  private handleOnClose() {
    this.isVisible.value = false;

    setTimeout(() => {
      this.currentToastId.value && this.toastQueue.delete(this.currentToastId.value);
      this.currentToastId.value = undefined;
    }, 300);
  }

  render() {
    const eventDetail = this.currentToastId.value ? this.toastQueue.get(this.currentToastId.value) : undefined;
    const position = eventDetail?.position || "bottom";

    return (
      <div
        class={classNames("fixed z-[60] right-0 my-4 mr-4 w-fit", {
          "bottom-0": position === "bottom",
          "top-0": position === "top",
        })}
      >
        <div
          class={classNames(`transform transition-all duration-300`, {
            "translate-x-0": this.isVisible.value,
            "translate-x-[150%]": !this.isVisible.value,
          })}
        >
          {eventDetail && (
            <NWToast
              icon={eventDetail.icon}
              title={eventDetail.title}
              message={eventDetail.message}
              action={eventDetail.action}
              withGraphic={eventDetail.withGraphic}
              onClose={this.handleOnClose}
              dismissable={eventDetail.dismissType !== "manual"}
            ></NWToast>
          )}
        </div>
      </div>
    );
  }
}
