import { bind, Component, h, OmiProps, signal, tag } from "omi";
import isEmpty from "lodash-es/isEmpty";

import { PagesOnboarding } from "@/pages/project/features/onboarding";
import { NWToolbar } from "@/pages/project/features/toolbar";
import { Project } from "@/api/models/project";
import { NWToastManager, triggerToast } from "@/components/base/nw-toast";
import { installPortfolio } from "@/pages/portfolio/install";
import { NWModalProvider } from "@/components/modals";
import { NWAuthManager } from "@/components/nw-auth-manager";
import { NWImpersonationBanner } from "@/components/nw-impersonation-banner";
import { AUTH_LOGGED_IN_TOAST_TRIGGER, ONBOARDING_HIDE_BG, TOOLBAR_SHOW_EXPLORE } from "@/globals/storageKeys";
import { ErrorService } from "@/services/errorService";
import { PurchaseNotificationService } from "@/services/purchaseNotificationService";
import { triggerNewFeatureToast } from "@/utils/featureRefresh";
import { getFromQueryString } from "@/utils/url";
import { Track } from "@/api";
import { NWProgressBar, NWSuspense, openModal } from "@/components";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";
import { isMobileDevice } from "@/utils";

import { NWDraggablePanel } from "./components/base/nw-draggable-panel";
import globalStyles from "./globals/styles.css?inline";
import { PagesProject } from "./pages/project";
import { UserHintService } from "./services/userHintService";
import styles from "./app.css?inline";

type Props = {
  project: Required<Project>;
  selectedTrack?: Track["id"];
};

@tag("project-app")
export default class extends Component<Props> {
  static css = [tailwind, globalStyles, styles];
  static propTypes = {
    project: Object,
    selectedTrack: String,
  };

  private purchaseNotificationService: PurchaseNotificationService | undefined;
  private userHintService = new UserHintService();

  private hasLoadedPortfolio = signal(false);

  install() {
    this.attachShadow({ mode: "open" });
    Store.project.setProject(this.props.project);
  }

  installed() {
    if (Store.user.isLoggedIn.value) {
      ErrorService.setUser(Store.user.info.value?.email);
    }
  }

  @bind
  private handleMobileExperience() {
    const hasNotSelectedTopics = isEmpty(Store.user.preferences.selectedCategories.value);
    const shouldTriggerLoggedInToast = getFromQueryString(AUTH_LOGGED_IN_TOAST_TRIGGER, true);

    if (Store.user.isLoggedIn.value) {
      if (Store.features.hasFeature("projects.navV2")) {
        openModal("mobileLock", {});
        return;
      }

      if (shouldTriggerLoggedInToast && hasNotSelectedTopics) {
        setTimeout(() => {
          Store.app.toggleNav(true, "mobile-first-time");
        }, 100);
      } else if (shouldTriggerLoggedInToast) {
        setTimeout(
          () =>
            triggerToast({
              icon: "check-circle-outline-green",
              message: "You're logged in! Let's learn 🎉",
              position: "top",
            }),
          500,
        );
      }
    } else {
      // Force login for mobile users
      openModal("login", {});
    }
  }

  @bind
  private handleDesktopExperience() {
    const shouldTriggerLoggedInToast = getFromQueryString(AUTH_LOGGED_IN_TOAST_TRIGGER, true);
    if (shouldTriggerLoggedInToast) {
      setTimeout(
        () =>
          triggerToast({
            icon: "check-circle-outline-green",
            message: "You're logged in! Let's learn 🎉",
            position: "top",
          }),
        500,
      );
    }

    /**
     * Handles the post onboarding experience once users have logged in.
     */
    if (Store.features.hasFeature("projects.navV2") && Store.user.isLoggedIn.value) {
      getFromQueryString(ONBOARDING_HIDE_BG, true);

      if (getFromQueryString(TOOLBAR_SHOW_EXPLORE, true) === "true") {
        setTimeout(() => Store.app.showToolbarView("directory"), 600);
      }
    }
  }

  @bind
  private handlePanelVisibilityChange(isVisible: boolean) {
    if (isVisible && this.userHintService.shouldSeeHint("portfolio_drag")) {
      this.userHintService.markHintAsSeen("portfolio_drag");
      this.updateSelf();
    }

    Store.app.isPortfolioOpen.value = isVisible;
  }

  ready() {
    if (isMobileDevice()) {
      this.handleMobileExperience();
    } else {
      this.handleDesktopExperience();
    }

    if (Store.features.hasFeature("projects.payments")) {
      import("@/services/purchaseNotificationService").then(({ PurchaseNotificationService }) => {
        this.purchaseNotificationService = new PurchaseNotificationService();
        this.purchaseNotificationService.process();
      });
    }

    setTimeout(() => triggerNewFeatureToast());
  }

  private isRootPath() {
    try {
      const url = new URL(window.location.href);
      return url.pathname === "/" || url.pathname === "";
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  render(props: OmiProps<Props>) {
    const { project, selectedTrack } = props;
    const isImpersonating = Store.app.isImpersonating.value;
    const showOnboarding = !Store.user.isLoggedIn.value && this.isRootPath();

    return (
      <div>
        {Store.features.hasFeature("projects.navV2") && <NWAuthManager withUI={false} />}
        <NWModalProvider />
        <NWToastManager />
        {isImpersonating && <NWImpersonationBanner />}

        <NWDraggablePanel
          fullWidth
          onPanelVisibilityChange={this.handlePanelVisibilityChange}
          showDragHandle={this.hasLoadedPortfolio.value}
          dragHintComponent={
            this.userHintService.shouldSeeHint("portfolio_drag") ? (
              <h4 className="hidden lg:flex md:flex-col md:items-center md:justify-start text-xl absolute -right-40 w-40 text-left text-gray-500 opacity-60 font-signature text-wrap">
                Drag me and see what happens 👀
              </h4>
            ) : null
          }
        >
          <NWSuspense
            slot="panel"
            loadFunc={async () => await installPortfolio()}
            onLoadComplete={PagesPortfolio => {
              this.hasLoadedPortfolio.value = true;
              return <PagesPortfolio />;
            }}
          />

          <div slot="main">
            <NWProgressBar />
            <PagesProject project={project} selectedTrack={selectedTrack} />
          </div>
        </NWDraggablePanel>

        <div>{showOnboarding ? <PagesOnboarding /> : <NWToolbar type="uncontrolled" />}</div>
      </div>
    );
  }
}
