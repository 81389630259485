import { bind, classNames, Component, computed, h, signal, tag } from "omi";

import { ProjectProPaywall } from "@/pages/project/features/proPaywall";
import { TrackEvents, TrackLoadedEvent } from "@/components/nw-track/events";
import { CompletedEvent, ValidationEvents } from "@/components/validations/events";
import { installEditor } from "@/editor/install";
import { ScrollService } from "@/services/scrollService";
import { networkSignal } from "@/utils/signals";
import { DefaultApi, Project, ProjectStatus, Track, Validation, ValidationStatus } from "@/api";
import {
  MarkerSelectedEvent,
  MissionButtonState,
  NWBadge,
  NWBlock,
  NWContentMenu,
  NWEditorSkeleton,
  NWError,
  NWIcon,
  NWLoadingSpinner,
  NWMetabox,
  NWMissionBtn,
  NWNav,
  NWSuspense,
  NWTrackManager,
  openModal,
} from "@/components";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";
import { initApi, openAllLinksInNewTab } from "@/utils";

import styles from "./style.css?inline";

type Props = {
  project: Required<Project>;
  selectedTrack?: Track["id"];
};

@tag("pages-project")
export class PagesProject extends Component<Props> {
  static css = [tailwind, styles];

  private api: DefaultApi = initApi();
  private scrollService: ScrollService | undefined;

  private introContent = networkSignal();
  private isIntroLoaded = signal<boolean>(false);
  private selectedTrack = signal<Track["id"]>(undefined);
  private validations = networkSignal<Validation[]>({ default: [] });
  private completedValidations = signal(new Set<string>());
  private projectUpdate = networkSignal<Project>();

  private isProjectCompletable = computed(() => {
    const validations = this.validations.signal.data.value;

    if (validations && validations.length > 0) {
      return validations.length === this.completedValidations.value.size;
    }

    // Default when there are no validations in a project.
    return true;
  });

  install() {
    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackProjectViewed(this.props.project.id);
    });
  }

  installed() {
    if (!Store.features.hasFeature("projects.editor")) {
      this.introContent.run(async () => {
        const content = await this.fetchIntroContent();
        setTimeout(() => openAllLinksInNewTab(this.shadowRoot, true), 0);
        return content;
      });
    }

    if (Store.project.doesUserHaveAccess()) {
      this.validations.run(async () => {
        const validations = await this.api.getProjectValidations({ projectId: Store.project.id.value || "" });
        this.completedValidations.value = this.getCompletedFromValidations(validations);
        return validations;
      });

      addEventListener(TrackEvents.TRACK_LOADED, this.handleTrackSelected as EventListener);
      addEventListener(ValidationEvents.COMPLETED, this.handleValidationCompleted as EventListener);
      addEventListener(ValidationEvents.EDITED, this.handleValidationEdited as EventListener);

      import("@/services/scrollService").then(({ ScrollService }) => {
        this.scrollService = new ScrollService();
        this.scrollService.startObservation();
      });
    }
  }

  @bind
  private async fetchIntroContent() {
    const content = await this.api.getProjectFile({ id: Store.project.id.value!, filename: "intro" });
    this.isIntroLoaded.value = true;
    return content;
  }

  @bind
  private scrollToStep(event: MarkerSelectedEvent): void {
    const { stepId } = event.detail;
    const scrollToEvent = new CustomEvent("scroll_to_step", { detail: { stepId } });
    dispatchEvent(scrollToEvent);
  }

  @bind
  private getCompletedFromValidations(validations: Validation[]): Set<string> {
    const completedValidations = new Set<string>();

    for (const { id, status } of validations) {
      if (status === ValidationStatus.Complete) {
        completedValidations.add(id!);
      }
    }

    return completedValidations;
  }

  @bind
  private handleTrackSelected(event: CustomEvent<TrackLoadedEvent>) {
    this.selectedTrack.value = event.detail.id;
  }

  @bind
  private handleValidationCompleted(event: CustomEvent<CompletedEvent>) {
    const { id } = event.detail;
    this.completedValidations.value.add(id);
    this.completedValidations.update();
  }

  @bind
  private handleValidationEdited(event: CustomEvent<CompletedEvent>) {
    const { id } = event.detail;
    this.completedValidations.value.delete(id);
    this.completedValidations.update();

    if (Store.project.isComplete()) {
      this.setMissionComplete(false, true);
    }
  }

  @bind
  private async handleMissionComplete(): Promise<void> {
    if (!this.isProjectCompletable.peek() || Store.project.isComplete()) {
      return;
    }

    const wasSuccessful = await this.setMissionComplete(true);

    if (!wasSuccessful) {
      return;
    }

    openModal("share", {
      projectId: Store.project.id.value!,
      linkedInPostData: Store.project.metadata.value?.sharetemplate,
    });

    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackProjectComplete(this.props.project.id);
    });
  }

  @bind
  private async setMissionComplete(state: boolean, updateImmediately: boolean = false): Promise<boolean> {
    const status = state ? ProjectStatus.PROJECT_COMPLETE : ProjectStatus.PROJECT_INCOMPLETE;

    const event = new CustomEvent("project_updated", { detail: { projectId: this.props.project.id, status } });
    dispatchEvent(event);

    if (updateImmediately) {
      // Used to bypass the waiting period for the project status when we want the UI to update immediately, i.e. editing a validation
      Store.project.status.value = status;
    }

    const res = await this.projectUpdate.run(
      async () =>
        await this.api.submitProjectUpdate({
          projectId: this.props.project.id,
          projectUpdate: { status },
        }),
    );

    if (res == null) {
      return false;
    }

    Store.project.setProject(res);
    return res.status === ProjectStatus.PROJECT_COMPLETE;
  }

  @bind
  private calculateMissionAccomplishedState(): MissionButtonState {
    if (this.isProjectCompletable.value && Store.project.isComplete()) {
      return MissionButtonState.Complete;
    }

    if (this.isProjectCompletable.value) {
      return MissionButtonState.Enabled;
    }

    return MissionButtonState.Disabled;
  }

  render({ project, selectedTrack }: Props) {
    const { metadata } = project;
    const missionAccomplishedState = this.calculateMissionAccomplishedState();
    const hasValidations = this.validations.signal.data.value?.length !== 0;
    const isProProject = Store.project.isProProject();
    const showProProjectPaywall = isProProject && !Store.project.isProProjectUnlockedForUser();

    return (
      <>
        <div>
          {showProProjectPaywall && (
            <div class="fixed bottom-0 left-0 right-0 z-50">
              <ProjectProPaywall />
            </div>
          )}
        </div>

        <div
          id="project-layout"
          class="page-layout xl:gap-16 2xl:gap-20 relative mx-auto sm:py-8 sm:px-9 transition-opacity duration-300"
        >
          <div>{!Store.features.hasFeature("projects.navV2") && <NWNav />}</div>
          <div>
            <NWBlock>
              <div class="flex flex-col items-center space-y-8 md:space-y-2">
                <NWBadge
                  withIndicator={!isProProject}
                  icon={isProProject ? "keyhole" : undefined}
                  iconSize={{ width: 9, height: 14 }}
                >
                  {isProProject ? "PRO-Project" : "Project"}
                </NWBadge>

                <div class="flex flex-col md:flex-row justify-center items-center gap-6 text-center">
                  <img src={metadata?.icon} class="max-w-16" />
                  <h1 class="flex content-center items-center self-stretch text-4xl font-semibold text-center">
                    {metadata?.title}
                  </h1>
                </div>

                <p id="headline" class="flex-1 m-0 text-gray-900 text-center">
                  {metadata?.description}
                </p>
              </div>
              <div class="flex py-12 mt-12 flex-col items-start gap-8 self-stretch border-y border-gray-200">
                <div class="flex flex-wrap items-start gap-x-8 gap-y-4 md:gap-12">
                  <NWMetabox label="DIFFICULTY" text={metadata?.difficulty}>
                    <NWIcon slot="icon" name="terminal-square" />
                  </NWMetabox>
                  <NWMetabox label="TIME" text={metadata?.time}>
                    <NWIcon slot="icon" name="hourglass" />
                  </NWMetabox>
                  <NWMetabox label="COST" text={metadata?.cost}>
                    <NWIcon slot="icon" name="currency-dollar" />
                  </NWMetabox>
                </div>

                {metadata?.needs && (
                  <div class="flex flex-col md:flex-row w-full justify-between items-start">
                    <NWMetabox label="WHAT YOU'LL NEED">
                      <NWIcon slot="icon" name="tool" />
                    </NWMetabox>

                    <div class="w-full md:w-[60%] my-2 md:my-0">
                      <ul class="list-outside">
                        {metadata?.needs.map(value => (
                          <li class="text-gray-700 text-sm list-disc p-0" unsafeHTML={{ html: value }}></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {metadata?.concepts && (
                  <div class="flex flex-col md:flex-row w-full justify-between items-start">
                    <NWMetabox label="AWS SERVICES">
                      <NWIcon slot="icon" name="amazon" />
                    </NWMetabox>

                    <div class="w-full md:w-[60%] my-2 md:my-0">
                      <ul class="list-outside">
                        {metadata.concepts.map(value => (
                          <li class="text-gray-700 text-sm list-disc p-0" unsafeHTML={{ html: value }}></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              <div class="w-full mt-8">
                {Store.features.hasFeature("projects.editor") ? (
                  <NWSuspense
                    loadFunc={async () => {
                      const [introContent, NWEditor] = await Promise.all([this.fetchIntroContent(), installEditor()]);
                      return { introContent, NWEditor };
                    }}
                    loadingComponent={() => <NWEditorSkeleton />}
                    onLoadComplete={({ introContent, NWEditor }) => (
                      <NWEditor
                        instanceId="intro"
                        content={introContent ?? "An error occured, please refresh the page and try again"}
                      />
                    )}
                  />
                ) : (
                  <div>
                    {this.introContent.signal.isLoading.value && <NWLoadingSpinner />}
                    {this.introContent.signal.data.value && (
                      <div id="intro-content" unsafeHTML={{ html: this.introContent.signal.data.value }}></div>
                    )}
                  </div>
                )}
              </div>

              {this.isIntroLoaded.value && (
                <div class={classNames("w-full", { "mb-[32vh]": showProProjectPaywall })}>
                  <NWTrackManager selected={selectedTrack} tracks={metadata.tracks ?? []} />
                </div>
              )}

              {this.isIntroLoaded.value && this.selectedTrack.value != null && (
                <div class="my-10">
                  <NWMissionBtn
                    isLoading={this.projectUpdate.signal.isLoading.value}
                    state={missionAccomplishedState}
                    onClick={this.handleMissionComplete}
                    hasValidations={hasValidations}
                    disabledTooltip={`You still have tasks to complete, good luck 🙏`}
                  />
                  {this.projectUpdate.signal.error.value && (
                    <div class="mt-2">
                      <NWError />
                    </div>
                  )}
                </div>
              )}
            </NWBlock>
          </div>

          <div class="overflow-clip xl:max-w-md xl:min-w-48">
            <NWContentMenu onMarkerSelected={this.scrollToStep} trackId={this.selectedTrack.value} />
          </div>
        </div>
      </>
    );
  }
}
