import { bind, classNames, Component, h, tag } from "omi";

import { NWCloseButton } from "@/components/atoms/nw-button/closeButton";
import { ToastEventDetail } from "@/components/base/nw-toast/models.ts";
import { NWGraphic } from "@/components/atoms/nw-graphic";
import { NWIcon } from "@/components";
import { tailwind } from "@/tailwind";

export * from "./utils";
export * from "./toast-manager";
export * from "./models";

export type ToastProps = { dismissable: boolean; onClose: () => void } & Omit<ToastEventDetail, "dismissType" | "id">;

@tag("nw-toast")
export class NWToast extends Component<ToastProps> {
  static css = [tailwind];
  static propTypes = {
    title: String,
    message: String,
    action: Object,
    onClose: Function,
    dismissable: Boolean,
    icon: String,
  };

  @bind
  handleClose(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onClose();
  }

  render({ title, message, action, withGraphic, icon, dismissable = true }: ToastProps) {
    return (
      <div class="bg-white justify-between border rounded-xl flex border-gray-300 shadow-md min-w-[400px] max-w-lg">
        {withGraphic && (
          <div class="bg-blue-50 w-1/3 rounded-l-xl flex items-center justify-center px-2">
            <NWGraphic mode="random" names={["gem", "hearts", "omg", "star", "wow", "yes"]} class="text-brand-600" />
          </div>
        )}
        <div class={classNames("py-4 pl-5 pr-1 text-sm flex items-start justify-between")} onClick={action?.onClick}>
          {icon && (
            <div class="mr-4">
              <NWIcon name={icon} width={20} height={20} />
            </div>
          )}

          <div class="mr-2 space-y-1.5 select-none">
            {title && <h3 class="text-gray-700 font-semibold">{title}</h3>}
            <p class="text-gray-600" unsafeHTML={{ html: message }}></p>
            {action && (
              <button class="text-brand-700 hover:text-brand-800 font-semibold cursor-pointer">{action?.label}</button>
            )}
          </div>
        </div>

        <div class="pt-2 pr-2.5">{dismissable && <NWCloseButton size="sm" onClick={this.handleClose} />}</div>
      </div>
    );
  }
}
